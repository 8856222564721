<template>
    <v-container
            fill-height
            fluid
            grid-list-xl
    >
        <v-layout
                justify-center
                wrap
        >
            <template v-if="loaded">
                <data-table ref="datatable"
                            :key="'OrgConsole'"
                            :api-end-point="apiurl"
                            :search="search"
                            :sort-order="sortOrder"
                            :table-name="'Org Console'"
                            :user_id="user_id"
                            :user_type="user_type"
                            :is-custom-filter="false"
                            :pre-selected-filters="filters.selectedFilters"
                            :isDetail="false"
                            :for-org-console="true"
                            v-on:refreshData="getOrgConsoleStats"
                            v-on:onLoadSuccessData="getLoadSuccessData"
                >
                    <template slot="datatable-actions">
                        <div class="action-buttons ml-3">
                            <button class="btn btn-primary">Export CSV</button>
                        </div>
                        <div class="action-buttons ml-3">
                            <router-link class="btn btn-primary" :to="'/manageVouchers'">Manage Vouchers</router-link>
                        </div>
                        <div class="action-buttons ml-3">
                            <router-link class="btn btn-primary" :to="'/managePlans'">Manage Plans</router-link>
                        </div>
                        <div class="action-buttons ml-3">
                            <router-link class="btn btn-primary" :to="'/managePlansV2'">Manage Plans V2</router-link>
                        </div>
                        <div class="action-buttons ml-3">
                            <router-link class="btn btn-primary" :to="'/manageOrgs'">Manage Orgs</router-link>
                        </div>
                        <div class="action-buttons ml-3">
                          <router-link class="btn btn-primary" :to="'/manageOrgPlans'">Manage Orgs Plans</router-link>
                        </div>
                    </template>

                    <template slot="filters">
                        <filters :filters="filters.orgConsoleOrganisation"></filters>
                        <filters :filters="filters.orgConsoleStatus"></filters>
                        <filters :filters="filters.getPlanTypeFilters"></filters>
                    </template>

                    <template slot="stats">
                        <div class="display-flex flex-row">
                            <span class="mt-2 ml-2">
                                Organisations = {{ organisations  }}
                            </span>
                            <span class="mt-2 ml-2">
                                Plans = {{ plans  }}
                            </span>
                            <span class="mt-2 ml-2">
                                Vouchers = {{ vouchers  }}
                            </span>
                            <span class="mt-2 ml-2">
                                Vouchers activated = {{ voucherActivated  }}
                            </span>
                            <span class="mt-2 ml-2">
                                Therapy bookings = {{ therapyBookings  }}
                            </span>
                        </div>
                    </template>
                </data-table>
            </template>

            <add-voucher v-if="showAddVoucherModal"
                         :visible="showAddVoucherModal"
                         v-on:submit=""
                         v-on:hide="showAddVoucherModal=false"
            ></add-voucher>
        </v-layout>
    </v-container>
</template>

<script>
    import DataTable from "../components/common/dataTable/DataTable";
    import Filters from "../components/common/dataTable/filters/Filters";

    export default {
        name: "OrgConsole",
        components: {
            DataTable,
            Filters
        },
        props: {
            user_id: {
                user_id: [Number, String]
            },
            user_type: {
                type: String
            },
            header: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                apiurl: 'org_console',
                search: {
                    placeholder: 'Search',
                    searchAble: false
                },
                sortOrder: [
                    {
                        field: 'plan_id',
                        sortField: 'plan_id',
                        direction: 'desc'
                    }
                ],
                filters: [],
                selected_filters: [],
                loaded: false,
                showAddVoucherModal: false,
                organisations: 0,
                plans: 0,
                vouchers: 0,
                voucherActivated: 0,
                therapyBookings: 0,
                tableData: null,
                totalCount: 0,
                lastPage: 0,
                hasMore: true,
                paginationDataCompleted: false,
            }
        },
        created() {
            this.fetchFilters();
        },
        methods: {
          getLoadSuccessData(data) {
            this.tableData = data.data;
            this.updatePaginationForTable()
          },
          updatePaginationForTable() {
            if (this.tableData && this.paginationDataCompleted) {
              this.tableData.total = this.totalCount;
              this.tableData.last_page = this.lastPage;
              this.tableData.has_more = this.tableData.current_page !== this.lastPage;
              this.$events.fire('pagination-set', this.tableData);
            }
          },
            fetchFilters() {
                this.$http.get(this.url + 'filters/orgConsole').then((res) => {
                    if (res.data) {
                        this.filters = res.data;
                        this.loaded = true;
                    } else {
                        alert('there is some problem');
                    }
                });
            },
            removeSortFromUrl(key, sourceURL) {
                let rtn = sourceURL.split("?")[0],
                    param,
                    params_arr = [],
                    queryString = (sourceURL.indexOf("?") !== -1) ? sourceURL.split("?")[1] : "";
                if (queryString !== "") {
                    params_arr = queryString.split("&");
                    for (var i = params_arr.length - 1; i >= 0; i -= 1) {
                        param = params_arr[i].split("=")[0];
                        if (param === key) {
                            params_arr.splice(i, 1);
                        }
                    }
                    rtn = rtn + "?" + params_arr.join("&");
                }
                return rtn;
            },
            getOrgConsoleStats(query) {
                let originalURL = this.url+'org_console/stats?'+query;
                let alteredURL  =  this.removeSortFromUrl("sort", originalURL);
                this.$http.get(alteredURL).then( response => {
                    this.organisations = response.data.organisations;
                    this.plans = response.data.plans;
                    this.vouchers = response.data.vouchers;
                    this.voucherActivated = response.data.active_vouchers;
                    this.therapyBookings = response.data.therapy_bookings;
                    this.totalCount = response.data.total_count;
                    this.lastPage = response.data.last_page;
                    this.paginationDataCompleted = true;
                    this.updatePaginationForTable()
                }).catch( error => {
                    console.log(error);
                });
            },
        },
    }
</script>
